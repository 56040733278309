<template>
  <div class="copy-week-popup">
    <div class="header">Copy to Another Week</div>
    <div class="week-pickers">
      <week-picker
        :type="pickerOptions.source.key"
        :desc-label="pickerOptions.source.label"
        @setWeek="handleSetWeek"
      />
      <week-picker
        :type="pickerOptions.target.key"
        :desc-label="pickerOptions.target.label"
        @setWeek="handleSetWeek"
      />
    </div>
    <div class="checkbox">
      <label>
        <input type="checkbox" ref="checkbox" />
        <span>Copy with timelog</span>
      </label>
    </div>
    <div class="actions">
      <button class="button button-primary" @click="submitCopy">Copy</button>
    </div>
  </div>
</template>

<script>
import WeekPicker from './controls/PopupCopyWeekPicker.vue'
import { showSnackbar } from '@/services'
import { mapMutations, mapGetters } from 'vuex'
import { differenceInDays, addDays } from 'date-fns'
import mtApi from '@/agGridV2/helpers/mt-api.helper'
export default {
  name: 'PopupCopyWeek',
  components: {
    WeekPicker
  },
  data() {
    return {
      pickerOptions: {
        source: {
          key: 'source',
          label: 'Source week'
        },
        target: {
          key: 'target',
          label: 'Target week'
        }
      },
      weekBuffer: {
        source: {},
        target: {}
      }
    }
  },
  computed: {
    ...mapGetters(['daysWeek', 'statuses', 'timeEntryRequestBody'])
  },
  methods: {
    ...mapMutations([
      'setViewLoader',
      'setWeek',
      'setFolders'
    ]),
    handleSetWeek(weekObject) {
      this.weekBuffer[weekObject.type] = weekObject.range
    },
    async submitCopy() {
      const sourceStart = this.weekBuffer.source.startDate
      const targetStart = this.weekBuffer.target.startDate
      if (this.timeEntryRequestBody.tasks.length) {
        showSnackbar({
          mode: 'error',
          text: 'You must submit time logs before copying week'
        })
        return false
      }
      if (sourceStart.toDateString() === targetStart.toDateString()) {
        showSnackbar({
          mode: 'error',
          text: 'Source and target values must be different'
        })
        return false
      }
      this.setViewLoader(true)
      let response = await mtApi.getTimeEntries({
        startDate: this.weekBuffer.target.startDate,
        endDate: this.weekBuffer.target.endDate
      })
      this.setViewLoader(false)
      if (response.length) {
        showSnackbar({
          mode: 'error',
          text: 'Target week contains time entries'
        })
        return false
      }

      // this part need relocate to weeklylog.vue
      this.setViewLoader(true)
      response = await mtApi.getTimeEntries({
        startDate: this.weekBuffer.source.startDate,
        endDate: this.weekBuffer.source.endDate
      })
      this.setViewLoader(false)
      const difference = differenceInDays(targetStart, sourceStart)
      let folders = this.updateTimeEntries(response, difference)
      this.setWeek({ range: this.weekBuffer.target, update: false }) // This need refact
      this.setFolders({
        folders,
        daysWeek: this.daysWeek,
        copyWeek: true,
        status: this.$refs.checkbox.checked ? this.statuses.NEW : this.statuses.INITIAL
      })
      showSnackbar({
        mode: 'success',
        text: 'Week has been coppied'
      })
      this.$emit('copyWeekPopup', true)
    },
    updateTimeEntries(entries, days) {
      entries.forEach((folder) => {
        folder.tasks.forEach((task) => {
          task.time_entries.forEach((timeEntry) => {

            timeEntry.start = addDays(new Date(timeEntry.start), days)

            // timeEntry.start = Number(timeEntry.start) + 86400000 * days

            if (!this.$refs.checkbox.checked) {
              timeEntry.duration = null
            }
          })
        })
      })
      return entries
    }
  }
}
</script>

<style lang="scss" scoped>
.copy-week-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}
.week-pickers {
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.checkbox {
  margin-right: auto;
  label {
    display: flex;
    align-items: center;
    color: $text-secondary;
    font-size: 14px;
    cursor: pointer;
  }
  input[type='checkbox'] {
    width: 15px;
    height: 15px;
    border: 1px solid $color-secondary;
    border-radius: 4px;
    background-color: #fff;
    margin-right: 8px;
    transition: $transition;
    background-position: center;
    background-size: 10px;
    background-repeat: no-repeat;
    cursor: pointer;

    &:checked {
      background-color: red;
      border-color: red;
      // background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.43941 0.853553C9.63468 0.658291 9.63468 0.341709 9.43941 0.146447C9.24415 -0.0488155 8.92757 -0.0488155 8.73231 0.146447L3.79289 5.08586L0.853553 2.14652C0.658291 1.95126 0.341709 1.95126 0.146447 2.14652C-0.0488155 2.34178 -0.0488155 2.65837 0.146447 2.85363L3.43934 6.14652C3.6346 6.34178 3.95118 6.34178 4.14645 6.14652C4.14647 6.1465 4.1465 6.14647 4.14652 6.14645L9.43941 0.853553Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }
}
</style>
